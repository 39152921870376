import { Component, OnInit } from '@angular/core';
import { LibraryService } from '../shared/library.service';
import { environment as ENV } from '../../environments/environment.prod';
import { NgForm } from '@angular/forms';

@Component({
	selector: 'app-vigilance',
	templateUrl: './vigilance.component.html',
	styleUrls: ['./vigilance.component.css']
})
export class VigilanceComponent implements OnInit {
  public baseUrl: string = ENV.BASE_URL;
  public PledgeData: any;
  public rvnlBulletinsData: any;
  public PerformanceData: any;
  public ContactInfoData: any;
  public RvnlAboutData: any;
  public lang:any = sessionStorage.getItem('lang');
  public SaveComplain:any;
  public isloded:boolean=false;
  public isloded1:boolean=false;
  dtOptions: any = {};

  constructor(
    private libraryService: LibraryService,
	) {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthMenu : [5, 10, 25],
      processing: true
    };
	}

  // saveComplain(complainDetails: NgForm){
  //   let firstName = complainDetails.value.firstName;
  //   let lastName = complainDetails.value.lastName;
  //   let email = complainDetails.value.email;
  //   let bio = complainDetails.value.bio;

  //   this.libraryService.getSaveComplain(firstName,lastName,email,bio).subscribe(result => {
  //     this.SaveComplain = result;
  //   });

  // }

  onClickSubmit(data){
    let fname = data.firstName;
    let lname = data.lastName;
    let toemailid = data.email;
    let bio = data.bio;
    if(toemailid && fname && lname && bio ){
      this.libraryService.getcomplaint_log(fname,lname,toemailid,bio).subscribe(result=>{
        this.SaveComplain = result;         
      })
      alert('email send'); 
      window.location.reload();
     }else{
       alert('please enter details');
     }
  }

	ngOnInit() {
    this.libraryService.getVigilancePledge(this.lang).subscribe(result => {
      this.PledgeData = result;
    });

    this.libraryService.getRvnlBulletins(this.lang).subscribe(result => {
      this.rvnlBulletinsData = result;
      this.isloded = true;
    });

    this.libraryService.getVigilancePerformance(this.lang).subscribe(result => {
      this.PerformanceData = result;
      this.isloded1 = true;
    });

    this.libraryService.getVigilanceContactInfo(this.lang).subscribe(result => {
      result.forEach(element => {
            element.email = (element.email.replace('@', '[at]')).replace('.', '[dot]');;
      });
      this.ContactInfoData = result;
    });

    this.libraryService.getVigilanceAboutRvnl(this.lang).subscribe(result => {
      this.RvnlAboutData = result;
    });

  }

}
