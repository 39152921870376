import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LibraryService {

  constructor(private http: HttpClient) { }

  getBanner(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getBanner/' + lang);
  }

  getHeaderLogo(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getHeaderLogo/' + lang);
  }

  getLastUpdatedDate(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getLastUpdatedDate/' + lang);
  }

  getFooterLogo(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getFooterLogo/' + lang);
  }

  getMainMenu(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getMainMenu/' + lang);
  }

  getTopSocialLinks(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getTopSocialLinks/' + lang);
  }

  getTopStockPrice(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getTopStockPrice/' + lang);
  }

  getTopLoginLinks(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getTopLoginLinks/' + lang);
  }

  getTopContactUs(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getTopContactUs/' + lang);
  }

  getTopLinks(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getTopLinks/' + lang);
  }

  getWhoWeAre(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getWhoWeAre/' + lang);
  }

  getManagementSpeak(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getManagementSpeak/' + lang);
  }

  getImportantAnnouncements(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getImportantAnnouncements/' + lang);
  }

  getImportantAnnouncementsList(lang){
    return this.http.get<any>(environment.apiURL + 'getImportantAnnouncementsList/' + lang);
  }

  getProjectHeading(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getProjectHeading/' + lang);
  }

  getProjectImg(lang){
    return this.http.get<any>(environment.apiURL + 'getProjectImg/' + lang);
  }

  getInvestors(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getInvestors/' + lang);
  }

  getRnvlNews(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getRnvlNews/' + lang);
  }

  getPhotoGallery(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getPhotoGallery/' + lang);
  }

  getFooterSocialLinks(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getFooterSocialLinks/' + lang);
  }

  getFooterUsefullLinks(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getFooterUsefullLinks/' + lang);
  }

  getFooterContactUs(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getFooterContactUs/' + lang);
  }

  getFaq(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getFaq/' + lang);
  }

  getPublicProcurementPolicy(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getPublicProcurementPolicy/' + lang);
  }

  getVendorInformation(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getVendorInformation/' + lang);
  }

  getKeyFigures(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getKeyFigures/' + lang);
  }

  getImportantNoticeAnnouncements(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getImportantNoticeAnnouncements/' + lang);
  }

  getImportantNoticeAnnouncementsList(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getImportantNoticeAnnouncementsList/' + lang);
  }

  getLatestResults(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getLatestResults/' + lang);
  }

  getActiveTenders(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getActiveTenders/' + lang);
  }

  submitTotalViews(params,lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'submitTotalViews/' + params + '/' + lang);
  }

  getTotalViews(params,lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getTotalViews/' + params + '/' + lang);
  }

  getAboutUs(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getAboutUs/' + lang);
  }

  getOurMissionAndVisionData(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getOurMissionAndVisionData/' + lang);
  }

  getAnnualReports(lang,year): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getAnnualReports/' + lang+'/'+year);
  }

  getYearAnnualReports(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getYearAnnualReports/' + lang);
  }

  getYearBordMeeting(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getYearBordMeeting/' + lang);
  }

  getYearGeneralDisclosures(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getYearGeneralDisclosures/' + lang);
  }

  getFinancialResultsYears(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getFinancialResultsYears/' + lang);
  }

  getShareholdingPatternYears(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getShareholdingPatternYears/' + lang);
  }

  getCorporateGovernanceYears(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getCorporateGovernanceYears/' + lang);
  }

  getInvestorComplaintsYears(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getInvestorComplaintsYears/' + lang);
  }

  getCompliancesReportYears(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getCompliancesReportYears/' + lang);
  }

  getCompliancesDisclosureYears(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getCompliancesDisclosureYears/' + lang);
  }

  getCompliancesDisclosureYearsSearch(lang,obj): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getCompliancesDisclosureYearsSearch/' + lang + '/' + obj);
  }

  getCompliancesReportYearsSearch(lang,obj): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getCompliancesReportYearsSearch/' + lang + '/' + obj);
  }

  getInvestorComplaintsYearsSearch(lang,obj): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getInvestorComplaintsYearsSearch/' + lang + '/' + obj);
  }

  getCorporateGovernanceYearsSearch(lang,obj): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getCorporateGovernanceYearsSearch/' + lang + '/' + obj);
  }

  getShareholdingPatternYearsSearch(lang,obj): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getShareholdingPatternYearsSearch/' + lang + '/' + obj);
  }

  getFinancialResultsYearsSearch(lang,obj): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getFinancialResultsYearsSearch/' + lang + '/' + obj);
  }

  getYearAnnualReportsSearch(lang,obj): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getYearAnnualReportsSearch/' + lang + '/' + obj);
  }

  getYearGeneralDisclosuresSearch(lang,obj): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getYearGeneralDisclosuresSearch/' + lang + '/' + obj);
  }

  getYearBordMeetingSearch(lang,obj): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getYearBordMeetingSearch/' + lang + '/' + obj);
  }

  getOurObjectivesData(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getOurObjectivesData/' + lang);
  }

  getmousData(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getmousData/' + lang);
  }

  getBrands(lang):Observable<any>{
    return this.http.get<any>(environment.apiURL + 'getBrands/' + lang);
  }

  getVigilanceAboutRvnl(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getVigilanceAboutRvnl/' + lang);
  }

  getVigilancePledge(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getVigilancePledge/' + lang);
  }

  getRvnlBulletins(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getRvnlBulletins/' + lang);
  }

  getVigilancePerformance(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getVigilancePerformance/' + lang);
  }

  getVigilanceContactInfo(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getVigilanceContactInfo/' + lang);
  }

  getCorporateOffice(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getCorporateOffice/' + lang);
  }

  getPUIOffice(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getPUIOffice/' + lang);
  }

  getManagementDirectory(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getManagementDirectory/' + lang);
  }

  getPressReleaseData(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getPressReleaseData/' + lang);
  }

  getJobData(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getJobData/' + lang);
  }

  getNews(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getNews/' + lang);
  }

  getCategoryPhotoGallery(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getCategoryPhotoGallery/' + lang);
  }

  getPhotoGalleryAll(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getPhotoGalleryAll/' + lang);
  }

  getPhotoGalleryAllSearch(lang,searchData): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getPhotoGalleryAllSearch/' + lang + '/' + searchData);
  }

  getCategoryProjectsProgress(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getCategoryProjectsProgress/' + lang);
  }

  getProjectsProgressSearch(lang,searchData): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getProjectsProgressSearch/' + lang + '/' + searchData);
  }

  getProjectsProgress(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getProjectsProgress/' + lang);
  }

  getProjectsCompleted(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getProjectsCompleted/' + lang);
  }

  getProjectsCompletedSearch(lang,searchData): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getProjectsCompletedSearch/' + lang + '/' + searchData);
  }

  getRti(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getRti/' + lang);
  }

  getRtiPios(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getRtiPios/' + lang);
  }

  getPolicy(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getPolicy/' + lang);
  }

  getDisqualified(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getDisqualified/' + lang);
  }

  getCsr(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getCsr/' + lang);
  }

  getCsrstories(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getCsrstories/' + lang);
  }

  getMou(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getMou/' + lang);
  }

  getOurMous(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getOurMous/' + lang);
  }

  getSubsidiaries(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getSubsidiaries/' + lang);
  }

  getSaveComplain(firstName,lastName,email,bio): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getSaveComplain/' + firstName +'/'+ lastName +'/'+ email +'/'+ bio);
  }

  getSpvs(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getSpvs/' + lang);
  }

  getFooterExternalLinks(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getFooterExternalLinks/' + lang);
  }

  getProjectDetails(lang,projectId): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getProjectDetails/' + lang + '/' + projectId);
  }

  getRelatedProject(lang,category): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getRelatedProject/' + lang + '/' + category);
  }

  getManagementBoard(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getManagementBoard/' + lang);
  }

  getManagementBoardDetails(lang,managementBoardId): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getManagementBoardDetails/' + lang + '/' + managementBoardId);
  }

  getloginDetails(lang,loginId): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getloginDetails/' + lang + '/' + loginId);
  }

  getContractsAwarded(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getContractsAwarded/' + lang);
  }

  getBoardDirectors(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getBoardDirectors/' + lang);
  }

  getTerms(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getTerms/' + lang);
  }

  getFamiliar(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getFamiliar/' + lang);
  }

  getBoardMeetings(lang,year): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getBoardMeetings/' + lang + '/' + year);
  }

  getCompanyPolicies(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getCompanyPolicies/' + lang);
  }

  getGeneralDisclosures(lang,year): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getGeneralDisclosures/' + lang + '/' + year);
  }

  getInvestorInfoData(lang): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getInvestorInfoData/' + lang);
  }

  getNewsDetails(lang,newsId): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getNewsDetails/' + lang + '/' + newsId);
  }

  getAnnouncementsDetails(lang,id): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getAnnouncementsDetails/' + lang + '/' + id);
  }

  getFinancialResults(lang,year): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getFinancialResults/' + lang +'/'+ year);
  }

  getShareholdingPattern(lang,year): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getShareholdingPattern/' + lang + '/' + year);
  }

  getCorporateGovernance(lang,year): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getCorporateGovernance/' + lang + '/' + year);
  }

  getInvestorComplaints(lang,year): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getInvestorComplaints/' + lang + '/' + year);
  }

  getCompliancesReport(lang,year): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getCompliancesReport/' + lang+ '/' + year);
  }

  getCompliancesDisclosure(lang,year): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getCompliancesDisclosure/' + lang+ '/' + year);
  }

  getcomplaint_log(fname,lname,toemailid,bio): Observable<any> {
    return this.http.get<any>(environment.apiURL + 'getcomplaint_log/' + fname+ '/' + lname+ '/'+toemailid+'/'+bio);
  }

  getBoardCommittees(lang):Observable<any>{
    return this.http.get<any>(environment.apiURL + 'getBoardCommittees/' + lang);
  }
}
